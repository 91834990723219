import React, {FunctionComponent} from "react";
import {useState} from 'react'


type TUEVbyRegionProps = {};

export const TUEVbyRegion: FunctionComponent<TUEVbyRegionProps> = ((props: TUEVbyRegionProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button type={'button'} className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                TÜV nach Region
            </button>

            <div
                className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
            <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-berlin/"
                           title="HU/AU (TÜV) Berlin" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Berlin
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-au-tuev-bremen-dekra-gtue-oder-kues/"
                           title="HU/AU (TÜV) Bremen" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Bremen
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-bielefeld/"
                           title="HU/AU (TÜV) Bielefeld" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Bielefeld
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-bonn/"
                           title="HU/AU (TÜV) Bonn" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Bonn
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-duisburg/"
                           title="HU/AU (TÜV) Duisburg" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Duisburg
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-dortmund/"
                           title="HU/AU (TÜV) Dortmund" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Dortmund
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-duesseldorf/"
                           title="HU/AU (TÜV) Düsseldorf" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Düsseldorf
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-frankfurt/"
                           title="HU/AU (TÜV) Frankfurt" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Frankfurt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-au-tuev-landau-dekra-gtue-oder-kues/"
                           title="HU/AU (TÜV) Landau" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Landau
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-mainz/"
                           title="HU/AU (TÜV) Mainz" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            HU/AU (TÜV) Mainz
                        </a>
                    </li>
                     <li className={'pb-3'}>
                         <a href="https://www.repareo.de/hu-au-tuev-moenchengladbach-dekra-gtue-oder-kues/"
                            title="HU/AU (TÜV) Mönchengladbach" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                             HU/AU (TÜV) Mönchengladbach
                         </a>
                    </li>
                     <li className={'pb-3'}>
                         <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-muenchen/"
                            title="HU/AU (TÜV) München" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                             HU/AU (TÜV) München
                         </a>
                    </li>
                     <li className={'pb-3'}>
                         <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-moers/"
                            title="HU/AU (TÜV) Moers" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                             HU/AU (TÜV) Moers
                         </a>
                    </li>
                      <li className={'pb-3'}>
                          <a href="https://www.repareo.de/hu-au-tuev-nurnberg-dekra-gtue-oder-kues/"
                             title="HU/AU (TÜV) Nürnberg" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                              HU/AU (TÜV) Nürnberg
                          </a>
                    </li>
                      <li className={'pb-3'}>
                          <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-paderborn/"
                             title="HU/AU (TÜV) Paderborn" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                              HU/AU (TÜV) Paderborn
                          </a>
                    </li>
                      <li className={'pb-3'}>
                          <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-pforzheim/"
                             title="HU/AU (TÜV) Pforzheim" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                              HU/AU (TÜV) Pforzheim
                          </a>
                    </li>
                      <li className={'pb-3'}>
                          <a href="https://www.repareo.de/hu-au-tuev-tuttlingen-dekra-gtue-oder-kues/"
                             title="HU/AU (TÜV) Tuttlingen" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                              HU/AU (TÜV) Tuttlingen
                          </a>
                    </li>
                      <li className={'pb-3'}>
                          <a href="https://www.repareo.de/hu-au-tuev-wiesbaden-dekra-gtue-oder-kues/"
                             title="HU/AU (TÜV) Wiesbaden" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                              HU/AU (TÜV) Wiesbaden
                          </a>
                    </li>
                      <li className={'pb-3'}>
                          <a href="https://www.repareo.de/hu-bei-dekra-gtue-kues-oder-tuev-wuerzburg/"
                             title="HU/AU (TÜV) Würzburg" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                              HU/AU (TÜV) Würzburg
                          </a>
                    </li>
                </ul>
            </div>
        </div>
    )
})

