import React, {FunctionComponent} from "react";
import {useState} from 'react'


type CommonIssuesProps = {};

export const CommonIssues: FunctionComponent<CommonIssuesProps> = ((props: CommonIssuesProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button type={'button'} className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                Häufige Defekte
            </button>
            <div className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
                <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/lichtmaschine-defekt/" title="Lichtmaschine defekt"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Lichtmaschine defekt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/auspuff-defekt/" title="Auspuff defekt"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Auspuff defekt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/anlasser-defekt/" title="Anlasser defekt"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Anlasser defekt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oeldruckschalter-defekt/" title="Öldruckschalter defekt"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Öldruckschalter defekt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/abs-sensor-defekt/" title="ABS-Sensor defekt"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            ABS-Sensor defekt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/spurstangenkopf-defekt/" title="Spurstangenkopf defekt"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Spurstangenkopf defekt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/auto-springt-nicht-an/" title="Auto springt nicht an"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Auto springt nicht an
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
})

