import React, {FunctionComponent} from "react";
import {useState} from 'react'


type TireChangeWithCityProps = {};

export const TireChangeWithCity: FunctionComponent<TireChangeWithCityProps> = ((props: TireChangeWithCityProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                Reifenwechsel nach Stadt
            </button>

            <div className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
                <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-berlin/" title="Reifenwechsel Berlin"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Berlin
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-hamburg/" title="Reifenwechsel Hamburg"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Hamburg
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-muenchen/" title="Reifenwechsel München"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel München
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-koeln/" title="Reifenwechsel Köln"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Köln
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-frankfurt/" title="Reifenwechsel Frankfurt"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Frankfurt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-stuttgart/" title="Reifenwechsel Stuttgart"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Stuttgart
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-duesseldorf/" title="Reifenwechsel Düsseldorf"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Düsseldorf
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-dortmund/" title="Reifenwechsel Dortmund"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Dortmund
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-leipzig/" title="Reifenwechsel Leipzig"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Leipzig
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-duisburg/" title="Reifenwechsel Duisburg"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Duisburg
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/reifenwechsel-wuppertal/" title="Reifenwechsel Wuppertal"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Reifenwechsel Wuppertal
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
})

