import React, {FunctionComponent} from "react";
import {useState} from 'react'


type TurboChargerProps = {};

export const TurboCharger: FunctionComponent<TurboChargerProps> = ((props: TurboChargerProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button type={'button'} className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                Turbolader Defekte
            </button>
            <div className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
                <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/turbolader-defekt/" title="Turbolader defekt"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Turbolader defekt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/turbolader-reparatur/" title="Turbolader Reparatur"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Turbolader Reparatur
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/turbolader-ueberholen-instandsetzung/"
                           title="Turbolader überholen" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Turbolader überholen
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    )
})

