import React, {FunctionComponent} from "react";
import {useState} from 'react'


type OilChangeWithModelProps = {};

export const OilChangeWithModel: FunctionComponent<OilChangeWithModelProps> = ((props: OilChangeWithModelProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button type={'button'} className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                Ölwechsel nach Modell
            </button>
            <div className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
                <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-vw-golf-4/" title="Ölwechsel Golf 4"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Golf 4
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-vw-golf-5/" title="Ölwechsel Golf 5"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Golf 5
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-vw-golf-6/" title="Ölwechsel Golf 6"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Golf 6
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-vw-golf-7/" title="Ölwechsel Golf 7"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Golf 7
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-ford-fiesta/" title="Ölwechsel Ford Fiesta"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Ford Fiesta
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-ford-focus/" title="Ölwechsel Ford Focus"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Ford Focus
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-fiat-ducato/" title="Ölwechsel Fiat Ducato"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Fiat Ducato
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-skoda-octavia/" title="Ölwechsel Skoda Octavia"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Skoda Octavia
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-vw-polo/" title="Ölwechsel VW Polo"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel VW Polo
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-audi-a6/" title="Ölwechsel Audi A6"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Audi A6
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-vw-tiguan/" title="Ölwechsel VW Tiguan"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel VW Tiguan
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-opel-astra/" title="Ölwechsel Opel Astra"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Opel Astra
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-mercedes-c-klasse/"
                           title="Ölwechsel Mercedes C-Klasse"  className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Mercedes C-Klasse
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-vw-touran/" title="Ölwechsel VW Touran"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel VW Touran
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-bmw-x1/" title="Ölwechsel BMW X1"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel BMW X1
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-audi-a3/" title="Ölwechsel Audi A3"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Audi A3
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-audi-q5/" title="Ölwechsel Audi Q5"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Audi Q5
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-opel-corsa/" title="Ölwechsel Opel Corsa"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Opel Corsa
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-seat-ibiza/" title="Ölwechsel Seat Ibiza"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel Seat Ibiza
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/oelwechsel-bmw-x5/" title="Ölwechsel BMW X5"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Ölwechsel BMW X5
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    )
})