import React, {FunctionComponent} from "react";
import {useState} from 'react'


type CarSaleProps = {};

export const CarSale: FunctionComponent<CarSaleProps> = ((props: CarSaleProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button type={'button'} className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                Autoankauf
            </button>

            <div className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
                <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/magazin/testberichte/wir-kaufen-dein-auto/"
                           title="wirkaufendeinauto" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            wirkaufendeinauto
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/magazin/testberichte/abracar/" title="Abracar"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Abracar
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/magazin/testberichte/mobile-de-express-verkauf-erfahrung/"
                           title="Mobile.de Express Verkauf" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Mobile.de Express Verkauf
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/was-ist-mein-auto-wert-kostenlos-berechnen/" title="Schwacke Liste"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Schwacke Liste
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/auto-mit-motorschaden-verkaufen/"
                           title="Auto mit Motorschaden verkaufen" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Auto mit Motorschaden verkaufen
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
})

