import React, {FunctionComponent} from "react";
import {useState} from 'react'


type InspectionWithBrandAndModelProps = {};

export const InspectionWithBrandAndModel: FunctionComponent<InspectionWithBrandAndModelProps> = ((props: InspectionWithBrandAndModelProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                Inspektion nach Marke & Modell
            </button>
            <div className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
                <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw-golf-7/" title="Inspektion VW Golf 7"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW Golf 7
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw-polo/" title="Inspektion VW Polo"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW Polo
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-skoda-octavia/" title="Inspektion Skoda Octavia"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Skoda Octavia
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-bmw-1er/" title="Inspektion BMW 1er"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion BMW 1er
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw-golf-6/" title="Inspektion VW Golf 6"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW Golf 6
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw-tiguan/" title="Inspektion VW Tiguan"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW Tiguan
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-mercedes-c-klasse/"
                           title="Inspektion Mercedes C-Klasse" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Mercedes C-Klasse
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-bmw-3er/" title="Inspektion BMW 3er"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion BMW 3er
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw-touran/" title="Inspektion VW Touran"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW Touran
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-bmw-5er/" title="Inspektion BMW 5er"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion BMW 5er
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-skoda-fabia/" title="Inspektion Skoda Fabia"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Skoda Fabia
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-opel-insignia/" title="Inspektion Opel Insignia"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Opel Insignia
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-hyundai-tucson/"
                           title="Inspektion Hyundai Tucson" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Hyundai Tucson
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-opel-mokka/" title="Inspektion Opel Mokka"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Opel Mokka
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-seat-ibiza/" title="Inspektion Seat Ibiza"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Seat Ibiza
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw-caddy/" title="Inspektion VW Caddy"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW Caddy
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-mercedes-b-klasse/"
                           title="Inspektion Mercedes B-Klasse" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Mercedes B-Klasse
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-audi-a1/" title="Inspektion Audi A1"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Audi A1
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-bmw-2er/" title="Inspektion BMW 2er"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion BMW 2er
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw-golf-5/" title="Inspektion VW Golf 5"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW Golf 5
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw-up/" title="Inspektion VW Up"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW Up
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-bmw-x1/" title="Inspektion BMW X1"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion BMW X1
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-hyundai-i30/" title="Inspektion Hyundai i30"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Hyundai i30
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-audi-a5/" title="Inspektion Audi A5"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Audi A5
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-skoda-enyaq-iv/"
                           title="Inspektion Skoda Envaq iV" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Skoda Envaq iV
                        </a>

                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-hyundai-kona-elektro/"
                           title="Inspektion Hyundai Kona Elektro" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Hyundai Kona Elektro
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-kia-e-niro/" title="Inspektion Kia e-Niro"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Kia e-Niro
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-renault-zoe/" title="Inspektion Renault Zoe"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Renault Zoe
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
})