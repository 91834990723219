import React, {FunctionComponent} from "react";
import {Cambelt} from "./Linklists/Cambelt";
import {InspectionWithBrand} from "./Linklists/InspectionWithBrand";
import {InspectionWithBrandAndModel} from "./Linklists/InspectionWithBrandAndModel";
import {OilChangeWithModel} from "./Linklists/OilChangeWithModel";
import {TireChangeWithCity} from "./Linklists/TireChangeWithCity";
import {CommonIssues} from "./Linklists/CommonIssues";
import {TurboCharger} from "./Linklists/TurboCharger";
import {CarSale} from "./Linklists/CarSale";
import {TUEVbyRegion} from "./Linklists/TUEVbyRegion";

export type PopularServicesProps = {
    headline: string,
};

export const PopularServices: FunctionComponent<PopularServicesProps> = ((props: PopularServicesProps) => {
    return (
        <div className={`popularServices px-6 md:px-12 mb-12 grid grid-cols-12 gap-x-2 md:gap-x-8 lg:gap-x-12`}>
            <div className={'col-span-12'}>
                <h3 className={'text-lg font-serif leading-8 font-semibold text-gray-500 pb-8'}>{props.headline}</h3>
            </div>
            <Cambelt/>
            <InspectionWithBrand/>
            <InspectionWithBrandAndModel/>
            <OilChangeWithModel/>
            <TireChangeWithCity/>
            <CommonIssues/>
            <TurboCharger/>
            <CarSale/>
            <TUEVbyRegion/>
            <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
                <a href={'https://www.repareo.de/werkstatt-services/'} className={'text-base leading-6 font-semibold text-gray-500 text-left no-underline'}>Alle Services</a>
            </div>
            <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
                <a href={'https://www.repareo.de/werkstatt-profilseiten/'} className={'text-base leading-6 font-semibold text-gray-500 text-left no-underline'}>Alle Buchbaren Werkstätten</a>
            </div>
            <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
                <a href={'https://www.repareo.de/werkstatt-in-deiner-naehe/'} className={'text-base leading-6 font-semibold text-gray-500 text-left no-underline'}>Werkstatt in deiner Nähe</a>
                <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/berlin/"
                           title="Autowerkstätten in Berlin" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Berlin
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/muenchen/"
                           title="Autowerkstätten in München" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in München
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/hamburg/"
                           title="Autowerkstätten in Hamburg" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Hamburg
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/koeln/"
                           title="Autowerkstätten in Köln" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Köln
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/leipzig/"
                           title="Autowerkstätten in Leipzig" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Leipzig
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/frankfurt/"
                           title="Autowerkstätten in Frankfurt" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Frankfurt
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/duesseldorf/"
                           title="Autowerkstätten in Düsseldorf" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Düsseldorf
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/dresden/"
                           title="Autowerkstätten in Dresden" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Dresden
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/hannover/"
                           title="Autowerkstätten in Hannover" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Hannover
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/muenster/"
                           title="Autowerkstätten in Münster" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Münster
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/braunschweig/"
                           title="Autowerkstätten in Braunschweig" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Braunschweig
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/stuttgart/"
                           title="Autowerkstätten in Stuttgart" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Stuttgart
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/dortmund/"
                           title="Autowerkstätten in Dortmund" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Dortmund
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/essen/"
                           title="Autowerkstätten in Essen" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Essen
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/bremen/"
                           title="Autowerkstätten in Dortmund" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Bremen
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/nuernberg/"
                           title="Autowerkstätten in Nürnberg" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Nürnberg
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/goettingen/"
                           title="Autowerkstätten in Göttingen" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Göttingen
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/werkstatt/mainz/"
                           title="Autowerkstätten in Mainz" className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Autowerkstätten in Mainz
                        </a>
                    </li>
                </ul>
            </div>
            <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
                <a href={'https://www.repareo.de/dekra-stundenverrechungssatz/'} className={'text-base leading-6 font-semibold text-gray-500 text-left no-underline'}>Dekra-Stunden&shy;verrechungs&shy;satz</a>
            </div>


        </div>
)
})