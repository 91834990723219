import React, {FunctionComponent} from "react";
import {useState} from 'react'


type InspectionWithBrandProps = {};

export const InspectionWithBrand: FunctionComponent<InspectionWithBrandProps> = ((props: InspectionWithBrandProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button type={'button'} className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                Inspektion nach Marke
            </button>
            <div className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
                <ul className={'mt-3'}>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-audi/" title="Inspektion Audi"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Audi
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-vw/" title="Inspektion VW"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion VW
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-bmw/" title="Inspektion BMW"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion BMW
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-mercedes-benz/" title="Inspektion Mercedes-Benz"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Mercedes-Benz
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-ford/" title="Inspektion Ford"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Ford
                        </a>
                    </li>
                    <li className={'pb-3'}>
                        <a href="https://www.repareo.de/inspektion-opel/" title="Inspektion Opel"
                           className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                            Inspektion Opel
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
})