import React, {FunctionComponent} from "react";
import {useState} from 'react'


type CambeltProps = {};

export const Cambelt: FunctionComponent<CambeltProps> = ((props: CambeltProps) => {
    const [isShowing, setIsShowing] = useState(true)
    return (
        <div className={'col-span-6 md:col-span-4 xl:col-span-3 mb-3'}>
            <button type={'button'} className={'text-sm leading-5 md:text-base md:leading-6 font-semibold text-gray-500 text-left'}
                    onClick={() => setIsShowing((isShowing) => !isShowing)}>
                Zahnriemenwechsel
            </button>
            <div className={`collapseable ${!isShowing && 'h-0'} ${isShowing && 'h-full'} overflow-hidden transition-[height] duration-700`}>
               <ul className={'mt-3'}>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-audi-a4/" title="Zahnriemenwechsel A4"
                       className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel A4
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-renault-scenic/"
                       title="Zahnriemenwechsel Renault Scenic"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Renault Scenic
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-vw-polo/"
                       title="Zahnriemenwechsel Polo"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Polo
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-ford-focus/"
                       title="Zahnriemenwechsel Ford Focus"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Ford Focus
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-vw-golf-4/"
                       title="Zahnriemenwechsel Golf 4"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Golf 4
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-golf-5/"
                       title="Zahnriemenwechsel Golf 5"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Golf 5
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-vw-golf-6/"
                       title="Zahnriemenwechsel Golf 6"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Golf 6
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-golf-7/"
                       title="Zahnriemenwechsel Golf 7"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Golf 7
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-audi-a3/" title="Zahnriemenwechsel A3"
                       className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel A3
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-vw-tiguan/"
                       title="Zahnriemenwechsel Tiguan"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Tiguan
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-ford-fiesta/"
                       title="Zahnriemenwechsel Ford Fiesta"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Ford Fiesta
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-fiat-500/"
                       title="Zahnriemenwechsel Fiat 500"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Fiat 500
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-seat-leon/"
                       title="Zahnriemenwechsel Seat Leon"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Seat Leon
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-skoda-fabia/"
                       title="Zahnriemenwechsel Skoda Fabia"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Skoda Fabia
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/skoda-octavia-zahnriemenwechsel-wann/"
                       title="Zahnriemenwechsel Skoda Octavia"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Skoda Octavia
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-vw-caddy/"
                       title="Zahnriemenwechsel VW Caddy"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel VW Caddy
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-renault-clio/"
                       title="Zahnriemenwechsel Renault Clio"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Renault Clio
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-opel-insignia/"
                       title="Zahnriemenwechsel Opel Insignia"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Opel Insignia
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-vw-touran/"
                       title="Zahnriemenwechsel VW Touran"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel VW Touran
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-audi-a6/"
                       title="Zahnriemenwechsel Audi A6"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Audi A6
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-peugeot-207/"
                       title="Zahnriemenwechsel Peugeot 207"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Peugeot 207
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-opel-meriva/"
                       title="Zahnriemenwechsel Opel Meriva"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Opel Meriva
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-fiat-punto/"
                       title="Zahnriemenwechsel Fiat Punto"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Fiat Punto
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-renault-megane/"
                       title="Zahnriemenwechsel Renault Megane"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Renault Megane
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-volvo-v70/"
                       title="Zahnriemenwechsel Volvo V70"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Volvo V70
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-nissan-qashqai/"
                       title="Zahnriemenwechsel Nissan Qashqai"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Nissan Qashqai
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-citroen-berlingo/"
                       title="Zahnriemenwechsel Citroen Berlingo"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Citroen Berlingo
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-renault-twingo/"
                       title="Zahnriemenwechsel Renault Twingo"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel Renault Twingo
                    </a>
                </li>
                <li className={'pb-3'}>
                    <a href="https://www.repareo.de/zahnriemenwechsel-vw-sharan/"
                       title="Zahnriemenwechsel VW Sharan"   className={'text-sm leading-5 font-semibold text-gray-400 no-underline'}>
                        Zahnriemenwechsel VW Sharan
                    </a>
                </li>
            </ul>
            </div>
        </div>
    )
})